<template>
  <div class="bar">
    <canvas
      class="canvas"
      :id="`myChartBar${id}`"
      height="250px"
      width="500px"
      min-height="250px"
      min-width="500px"
    ></canvas>
  </div>
</template>

<script>
export default {
  name: "MixedVazio",
  props: {
    data: Array,
    label: String,
    labels: Array,
    position: String,
    variavel: Object,
    id: Number,
  },
  data() {
    return {
      lab: null,
      sum: 0,
      dataSetsCustom: [],
      MyChartBar: null,
    };
  },

  methods: {
    createChart() {
      const ctx = document.getElementById(`myChartBar${this.id}`);
      this.MyChartBar = new Chart(ctx, {
        type: "bar",
        data: {
          datasets: [
            {
              label: "NA",
              data: [10, 20, 30, 40],
              backgroundColor: [
                "rgb(0, 0, 0, 0.2)",
                "rgb(1, 1, 1, 0.2)",
                "rgb(51, 51, 51, 0.2)",
                "rgb(52, 52, 52, 0.2)",
              ],
            },
            {
              label: "NA",
              yAxisID: "first-y-axis",
              data: [50, 20, 40, 30],
              borderColor: ["rgb(0, 0, 0, 0.2)"],
              backgroundColor: [
                "rgb(0, 0, 0, 0.2)",
                "rgb(1, 1, 1, 0.2)",
                "rgb(51, 51, 51, 0.2)",
                "rgb(52, 52, 52, 0.2)",
              ],

              type: "line",
              fill: false,
            },
          ],
          labels: ["1", "2", "3", "4"],
        },
        options: {
          maintainAspectRatio: true,
          responsive: false,
          // scaleBeginAtZero: true,
          hover: false,
          tooltips: false,
          legend: {
            position: "top",
            labels: {
              boxWidth: 10,
              boxHeight: 10,
              textAlign: "left",
              useLineStyle: true,
            },
            onClick: null,
          },
          layout: {
            padding: [10, 5],
          },
          scales: {
            yAxes: [
              {
                id: "first-y-axis",
                type: "linear",
                position: "left",

                stacked: true,
                ticks: {
                  display: false,
                },
                gridLines: {
                  color: "transparent",
                },
              },
              {
                id: "second-y-axis",
                type: "linear",
                position: "right",

                ticks: {
                  display: false,
                },
                gridLines: {
                  color: "transparent",
                },
              },
            ],
            xAxes: [
              {
                display: false,
              },
            ],
          },
          title: {
            display: false,
          },
        },
      });
    },
  },

  created() {},

  mounted() {
    if (this.MyChartBar != null) {
      this.MyChartBar.destroy();
    }
    this.createChart();
  },
};
</script>

<style lang="scss" scoped>
.bar {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.canvas {
  display: flex;
}
</style>
