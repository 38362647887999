<template>
  <div class="inicial-container-reserv">
    <div class="label">
      <h5 class="reservadasTitle">
        Selecione os atributos que deseja remover da análise:
        <span class="material-symbols-outlined helpIcon">
          help
          <md-tooltip class="md-layout-item md-size-33 md-small-size-66">
            Atributos utilizados como chave de ligação entre bases, ou variáveis
            sem objetivo preditivo e serão <strong>desconsiderados</strong> na
            análise.
            <br />
            Mesmo se <strong>desativada</strong>, serão removidos os atributos
            listados abaixo. <br />
            As variáveis <strong>já selecionadas</strong> nos inputs anteriores
            também serão removidas da análise.
          </md-tooltip>
        </span>
      </h5>
      <b-form-checkbox
        type="checkbox"
        class="checkConfirm"
        id="checkConfirmReserv"
        @click="checkReserv = !checkReserv"
        @change="loadSelectReserv()"
        v-model="checkReserv"
        switch
        size="lg"
      />
    </div>
    <md-field class="selectCol">
      <label for="varReservSelecionada"
        >Selecione as Variáveis Reservadas
      </label>
      <md-select
        name="varReservSelecionada"
        id="varReservSelecionada"
        multiple
        @input="verificaVariaveisReservadas()"
        v-model="varReservadasSelecionadas"
        :placeholder="
          this.varReservadasMemory.length != 0
            ? this.varReservadasMemory + ' (Selecionadas)'
            : optionsListReservada <= 1
            ? 'Nenhuma coluna identificada'
            : 'Selecione as Variáveis Reservadas'
        "
        :disabled="!checkReserv"
      >
        <div class="textSearch">
          <md-input
            v-model="searchValueReservada"
            class="textSearchInput"
            placeholder="Procure uma coluna"
          >
          </md-input>
        </div>
        <md-option
          v-for="item in optionsListReservada"
          :key="item"
          :value="item"
        >
          {{ item }}
        </md-option>
      </md-select>
    </md-field>
    <div class="selectedCols">
      <strong class="selectedTitle"
        >Conjunto de atributos a serem efetivamente removidos:</strong
      >
      <div class="boxReservadas" v-if="this.reservadasAtualizadas.length == 0">
        <div v-for="atr in reservadasAtualizadasMemory" :key="atr">
          <li class="text-left">
            {{ atr }}
          </li>
        </div>
        <div class="text-center spinnerBox" v-if="libera">
          <Loading class="spinner" />
        </div>
      </div>
      <div class="boxReservadas" v-if="this.reservadasAtualizadas.length != 0">
        <div v-for="atr in reservadasAtualizadas" :key="atr">
          <li class="text-left">
            {{ atr }}
          </li>
        </div>
        <div class="text-center spinnerBox" v-if="libera">
          <Loading class="spinner" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { lotesService } from "@/services";
import { mapState, mapActions } from "pinia";
import { useSkynetStore } from "@/stores/skynet";
import { useSkynetMemoryStore } from "@/stores/skynetMemory";
// import { storeToRefs } from "pinia";

import Loading from "@/components/Loading";

export default {
  name: "VariavelReservada",

  components: {
    Loading,
  },
  props: {
    projectName: String,
    libera: Boolean,
  },

  data() {
    return {
      varReservadasSelecionadas: [],
      varReservadasSelecionadasBef: [],
      reservadasAtualizadas: [],
      identificador: this.$route.params.id,
      cabecalhoSaida: [],
      searchValueReservada: "",
      selectCovar: [],
      checkReserv: false,
    };
  },

  methods: {
    verificaVariaveisReservadas() {
      this.setVarReservadas(this.varReservadasSelecionadas);
      this.setPreSelecionadas(this.varReservadasSelecionadas);

      this.setCovarSelect(this.reservadasAtualizadas);
      this.reservadasAtualizadas = this.reservadasAtualizadasMemory;
    },

    loadSelectReserv() {
      this.$emit("recebeCheckReserv", this.checkReserv);
      if (this.checkReserv == false) {
        this.varReservadasSelecionadas = [];
        this.setPreSelecionadas(this.varReservadasSelecionadas);
      }
    },
    ...mapActions(useSkynetStore, [
      "updateColUnit",
      "addCol",
      "setPreSelecionadas",
      "setCovarSelect",
    ]),
    ...mapActions(useSkynetMemoryStore, ["setVarReservadas"]),
  },

  computed: {
    ...mapState(useSkynetStore, [
      "availableCols",
      "varReservadas",
      "cabecalhoSaidaSelect",
      "covarReservadas",
      "preSelecionadas",
      "reservadasAtualizadasMemory",
    ]),
    ...mapState(useSkynetMemoryStore, ["varReservadasMemory"]),

    optionsListReservada() {
      if (this.searchValueReservada.trim().length > 0) {
        let temp = this.cabecalhoSaidaSelect.filter(
          (x) => !this.covarReservadas.includes(x)
        );

        return temp.filter((column) =>
          column.toLowerCase().includes(this.searchValueReservada.trim())
        );
      }
      return this.cabecalhoSaidaSelect.filter(
        (x) => !this.covarReservadas.includes(x)
      );
    },
  },
  created() {
    this.reservadasAtualizadas = this.reservadasAtualizadasMemory;
    
    if (this.varReservadasMemory.length != 0) {
      this.checkReserv = true;
      this.loadSelectReserv();
      this.varReservadasSelecionadas = this.varReservadasMemory;
      this.verificaVariaveisReservadas();
      this.searchValueReservada = "";
    }
  },

};
</script>

<style  lang="scss" scoped>
.inicial-container-reserv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  width: 100%;
}
.label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 0;
  top: -0.8rem;
}
.reservadasTitle {
  font-size: 1rem;
  margin: 10px 0 0 0;
}

.selectCol {
  width: 80%;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  float: left;
  cursor: pointer;

  @include mediaQueryMax(small) {
    width: 100%;
  }
}

.selectedCols {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 80%;
  min-width: 60%;
  background-color: transparent;

  @include mediaQueryMax(medium) {
    min-width: 90%;
    max-width: 100%;
  }
  @include mediaQueryMax(small) {
    max-width: 100%;
  }
}
.selectedTitle {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0 0 10px 0;
}

.boxReservadas {
  border: 2px dashed var(--accent-60);
  border-radius: 10px;
  overflow-y: scroll;
  background-color: transparent;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  word-break: break-all;
  min-width: 90%;
}

.spinnerBox {
  width: 100%;
  background-color: transparent;
  z-index: 10;
}

.textSearch {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}
.textSearchInput {
  display: flex;
  width: 95%;
  height: 35px;
  border-width: 0px 0px 0.1rem 0px;
  font-size: small;
}

.helpIcon {
  cursor: pointer;
  color: #209cee;
  font-size: 1.5rem;
  margin-left: 0.3rem;
}

</style>