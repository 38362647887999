<template>
  <div class="inicial-container-safra-amostra">
    <h5 class="title">
      Taxa de Evento e Representatividade por Safra e Amostra:
      <span class="material-symbols-outlined helpIcon">
        help
        <md-tooltip class="md-layout-item md-size-33 md-small-size-66">
          Exibe a taxa de Maus em cada <strong>Safra</strong> de acordo com a
          <strong>Data de Referência</strong> mapeada, segmentado por amostra,
          assim como a representatividade de cada amostra por Safra.
        </md-tooltip>
      </span>
    </h5>
    <div class="chart" v-if="liberaSafraDataRef">
      <div
        class="chartBox"
        v-if="
          varAmostraSelecionada &&
          varAmostra &&
          !errorMsgAmostra &&
          liberaChart &&
          valido &&
          eventoPorSafra &&
          varAmostraQuantidades
        "
      >
        <p class="chartTitle">
          <strong>Amostra por Safra</strong>
        </p>
        <StackedSafraAmostra
          :id="id + 17"
          :dataSafra="eventoPorSafra"
          :key="varAmostraSelecionada"
          :varAmostraQuantidades="varAmostraQuantidades"
        />
      </div>
      <div
        class="chartBoxEmpty"
        id="mixedChartDefault"
        v-if="!varAmostra && !errorMsgAmostra && !liberaLoad"
      >
        <MixedVazio :id="id + 8" />
      </div>
      <div
        class="chartBox"
        v-if="
          varAmostraSelecionada &&
          varAmostra &&
          !errorMsgAmostra &&
          liberaChart &&
          valido &&
          eventoPorSafra &&
          varAmostraQuantidades &&
          eventoPorSafraAmostraComposta
        "
      >
        <p class="chartTitle">
          <strong>Taxa de Eventos por Safra e Amostra</strong>
        </p>
        <MixedSafraAmostra
          :id="id + 20"
          :data="eventoPorSafraAmostraComposta"
          :key="varAmostraSelecionada"
        />
      </div>
      <md-card class="cardError" v-if="!varAmostra && errorMsgAmostra">
        <span class="material-icons icon-error task">highlight_off</span>
        <h5 class="task errorAlert">{{ errorMsgAmostra }}</h5>
      </md-card>
      <md-card class="cardError" v-if="!varAmostra && errorMsg">
        <span class="material-icons icon-error task">highlight_off</span>
        <h5 class="task errorAlert">{{ errorMsg }}</h5>
      </md-card>
      <md-card
        class="cardError"
        v-if="varAmostra && !errorMsgAmostra && !valido"
      >
        <span class="material-symbols-outlined icon">fmd_bad</span>
        <h5 class="task errorAlert">
          Selecione uma <strong>Variável Amostra</strong> válida
        </h5>
      </md-card>
    </div>
    <div class="boxDisable" v-if="!liberaSafraDataRef">
      <md-card class="cardDisable">
        <span class="material-symbols-outlined icon">fmd_bad</span>
        <p class="textData errorAlert">
          As informações relacionadas à <strong>Safra</strong> estão
          <strong>desabilitadas</strong> pois não foi mapeada uma
          <strong>Data de Referência</strong> para este Lote.
        </p>
      </md-card>
    </div>
  </div>
</template>

<script>
import StackedSafraAmostra from "@/components/Integracao/Charts/StackedSafraAmostra";
import MixedVazio from "@/components/Integracao/Charts/MixedVazio";
import MixedSafraAmostra from "@/components/Integracao/Charts/MixedSafraAmostra";
import { useSkynetStore } from "@/stores/skynet";
import { useSkynetMemoryStore } from "@/stores/skynetMemory";
import { mapState } from "pinia";
import { historicoService } from "@/services";

export default {
  name: "safraAmostra",

  components: {
    StackedSafraAmostra,
    MixedVazio,
    MixedSafraAmostra,
  },

  props: {
    id: Number,
    liberaSafraDataRef: Boolean,
    varAmostraSelecionada: String,
    trainSampleSelecionada: String,
    varAmostra: Object,
    errorMsgAmostra: String,
    errorMsg: String,
    valido: Boolean,
    liberaLoad: Boolean,
    varAmostraQuantidades: Object,
  },

  data() {
    return {
      eventoPorSafra: null,
      liberaChart: false,
      eventoPorSafraAmostraComposta: null,
    };
  },

  methods: {
    async recuperaEventoPorSafra() {
      this.eventoPorSafra = null;

      const objetoHistorico = {
        identificacao_execucao: this.$route.params.id,
        coluna_safra: this.dataRefColumn,
        formato_safra: this.formatData,
        coluna_principal: this.varAmostraMemory[this.id],
        ignorar_valores_NA_principal: true,
        limite_contagem: 50,
      };

      const query = new URLSearchParams(objetoHistorico);
      try {
        this.eventoPorSafra = await historicoService.recuperaTaxaEventoPorSafra(
          query.toString()
        );
        this.liberaChart = true;
      } catch (error) {}
    },

    async recuperaEventoPorSafraComposto() {
      this.eventoPorSafraAmostraComposta = null;
      const objetoHistorico = {
        identificacao_execucao: this.$route.params.id,
        coluna_safra: this.dataRefColumn,
        formato_safra: this.formatData,
        coluna_principal: this.varAmostraMemory[this.id],
        coluna_secundaria: this.varRespMemory,
        limite_contagem: 50,
      };
      const query = new URLSearchParams(objetoHistorico);
      try {
        this.eventoPorSafraAmostraComposta =
          await historicoService.recuperaTaxaEventoCompostaPorSafra(
            query.toString()
          );
        this.liberaChart = true;
      } catch (error) {}
    },
  },

  created() {},

  computed: {
    ...mapState(useSkynetStore, ["formatData", "dataRefColumn"]),

    ...mapState(useSkynetMemoryStore, ["varRespMemory", "varAmostraMemory"]),
  },
};
</script>

<style  lang="scss" scoped>
.inicial-container-safra-amostra {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 350px;
  width: 100%;

  @include mediaQueryMax(medium) {
    min-height: 650px;
  }
}

.title {
  margin: 10px 0;
  display: flex;
  font-size: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 2rem;
}
.helpIcon {
  cursor: pointer;
  color: #209cee;
  font-size: 1.5rem;
  margin-left: 0.3rem;
}
.label {
  display: flex;
  justify-content: flex-start;
  margin: 5px 0px 0 0 !important;
  padding: 0px 20px;
  width: 100%;
}
.chart {
  width: 100%;
  height: 90%;
  display: flex;
  padding: 0%;
  margin: 0;
  @include flex-center(row);

  @include mediaQueryMax(medium) {
    flex-direction: column;
  }
}
.chartTitle {
  margin: 10px;
}
.chartBox {
  height: 90%;
  width: 50%;
  margin: 5px;
  @include flex-center(column);
  transition: 0.5s ease all;

  @include mediaQueryMax(medium) {
    width: 100%;
  }
}
.chartBoxEmpty {
  height: 90%;
  margin: 5px;
  @include flex-center(column);
  transition: 0.5s ease all;

  @include mediaQueryMax(medium) {
    justify-content: flex-start;
    width: 100%;
  }
}
.cardError {
  width: 60%;
  justify-self: flex-start;
  padding: 10px;
  box-sizing: border-box;

  @include flex-center(column);

  @include mediaQueryMax(small) {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 9rem;
  }
}
.task {
  display: inline-block;
}

.errorAlert {
  width: 90%;
  margin: 10px 5px 10px 5px !important;
  box-sizing: border-box;
  font-size: 1rem !important;
  word-break: break-word;
  white-space: pre-line;
}
.icon-error {
  color: rgba(255, 0, 0, 0.7);
  font-size: 2rem !important;
}
.boxDisable {
  display: flex;
  justify-content: center;
}
.cardDisable {
  width: 80%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textData {
  margin: 0 !important;
}
.errorAlert {
  width: 90%;
  margin: 10px 5px 10px 5px !important;
  box-sizing: border-box;
  font-size: 1rem !important;
  word-break: break-word;
  white-space: pre-line;
}
.icon {
  color: #209cee;
  font-size: 2rem !important;
}
</style>