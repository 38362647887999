<template>
  <div class="pie">
    <canvas
      class="canvas"
      :id="`myChart${id}`"
      height="200px"
    ></canvas>
  </div>
</template>

<script>
export default {
  name: "PieIntegr",
  props: {
    data: Array,
    label: String,
    labels: Array,
    position: String,
    variavel: Object,
    id: Number,
  },
  data() {
    return {
      lab: null,
    };
  },

  methods: {
    formatLabel() {
      let val = Object.values(this.variavel);
      let sum = val.reduce((a, b) => a + b, 0);
      this.lab = Object.keys(this.variavel);
      for (let i in this.lab) {
        this.lab[i] =
          "" + this.lab[i] + " (" + ((val[i] / sum) * 100).toFixed(1) + "%) ";
      }
    },
    createChart(dat, label) {
      const ctx = document.getElementById(`myChart${this.id}`);
      const myChart = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: this.lab,
          datasets: [
            {
              label: label,
              data: dat,
              backgroundColor: [
                "rgb(230, 25, 75, 0.4)",
                "rgb(60, 180, 75, 0.4)",
                "rgb(67, 99, 216, 0.4)",
                "rgb(245, 130, 49, 0.4)",
                "rgb(145, 30, 180, 0.4)",
                "rgb(70, 240, 240, 0.4)",
                "rgb(240, 50, 230, 0.4)",
                "rgb(188, 246, 12, 0.4)",
                "rgb(250, 190, 190, 0.4)",
                "rgb(0, 128, 128, 0.4)",
                "rgb(230, 190, 255, 0.4)",
                "rgb(154, 99, 36, 0.4)",
                "rgb(255, 250, 200, 0.4)",
                "rgb(128, 0, 0, 0.4)",
                "rgb(170, 255, 195, 0.4)",
                "rgb(128, 128, 0, 0.4)",
                "rgb(255, 216, 177, 0.4)",
                "rgb(0, 0, 117, 0.4)",
                "rgb(128, 128, 128, 0.4)",
                "rgb(255, 225, 25, 0.4)",
                "rgb(175, 255, 0, 0.4)",
                "rgb(175, 175, 0, 0.4)",
                "rgb(175, 0, 215, 0.4)",
                "rgb(135, 215, 255, 0.4)",
                "rgb(95, 0, 0, 0.4)",
                "rgb(0, 175, 255, 0.4)",
                "rgb(255, 0, 255, 0.4)",
                "rgb(0, 0, 95, 0.4)",
                "rgb(95, 0, 95, 0.4)",
              ],
              hoverBackgroundColor: [
                "rgb(230, 25, 75, 0.8)",
                "rgb(60, 180, 75, 0.8)",
                "rgb(67, 99, 216, 0.8)",
                "rgb(245, 130, 49, 0.8)",
                "rgb(145, 30, 180, 0.8)",
                "rgb(70, 240, 240, 0.8)",
                "rgb(240, 50, 230, 0.8)",
                "rgb(188, 246, 12, 0.8)",
                "rgb(250, 190, 190, 0.8)",
                "rgb(0, 128, 128, 0.8)",
                "rgb(230, 190, 255, 0.8)",
                "rgb(154, 99, 36, 0.8)",
                "rgb(255, 250, 200, 0.8)",
                "rgb(128, 0, 0, 0.8)",
                "rgb(170, 255, 195, 0.8)",
                "rgb(128, 128, 0, 0.8)",
                "rgb(255, 216, 177, 0.8)",
                "rgb(0, 0, 117, 0.8)",
                "rgb(128, 128, 128, 0.8)",
                "rgb(255, 225, 25, 0.8)",
                "rgb(175, 255, 0, 0.8)",
                "rgb(175, 175, 0, 0.8)",
                "rgb(175, 0, 215, 0.8)",
                "rgb(135, 215, 255, 0.8)",
                "rgb(95, 0, 0, 0.8)",
                "rgb(0, 175, 255, 0.8)",
                "rgb(255, 0, 255, 0.8)",
                "rgb(0, 0, 95, 0.8)",
                "rgb(95, 0, 95, 0.8)",
              ],

              borderWidth: 0,
            },
          ],
        },
        options: {
          responsive: false,
          scaleBeginAtZero: true,
          maintainAspectRatio: true,
          legend: {
            position: "top",
            labels: {
              boxWidth: 10,
              boxHeight: 10,
              textAlign: "left",
              useLineStyle: true,
            },
            onClick: null,
          },
          layout: {
            padding: [10, 5],
          },
          scales: {
            yAxes: {
              ticks: {
                display: false,
              },
              grid: {
                color: "transparent",
              },
            },
          },
          title: {
            display: false,
          },
        },
      });
    },
  },

  created() {
    this.formatLabel();
  },

  mounted() {
    if (this.myChart != null) {
      this.myChart.destroy();
    }
    this.createChart(this.data, this.label);
  },
};
</script>

<style lang="scss" scoped>
.pie {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.canvas {
  margin: 0px;

}
</style>