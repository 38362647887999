<template>
  <div class="card-expansion">
    <md-card class="md-card">
      <!-- <div class="lds-ring"><div></div><div></div><div></div><div></div></div> -->
      <img class="lds-ring" src="/img/loader-outbox.gif" />
    </md-card>
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style scoped>
.card-expansion {
  display: flex;
  justify-content: center;
  align-items: center;
}
.md-card {
  box-shadow: none;
  min-height: 80px;
  background: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lds-ring {
  display: inline-block;
  width: 200px;
  /* margin: 20px 0 0 0; */
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #2196f3;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2196f3 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
