<template>
  <div class="bar">
    <canvas
      class="canvas"
      :id="`myChartBarVazio${id}`"
      height="170"
      width="170"
    ></canvas>
  </div>
</template>

<script>
export default {
  name: "BarVazio",
  props: {
    id: Number,
  },
  data() {
    return {};
  },

  methods: {
    createChart() {
      const ctx = document.getElementById(`myChartBarVazio${this.id}`);
      this.MyChartBar = new Chart(ctx, {
        type: "bar",
        data: {
          labels: ["NA"],
          datasets: [
            {
              label: "NA",
              data: [3],
              backgroundColor: [
                "rgb(51, 51, 51, 0.2)",
                "rgb(0, 0, 0, 0.4)",
                "rgb(1, 1, 1, 0.4)",
                "rgb(52, 52, 52, 0.4)",
              ],
            },
          ],
          barThickness: 10,
          maxBarThickness: 30,


        },
        options: {
          maintainAspectRatio: true,
          responsive: false,
          scaleBeginAtZero: true,
          tooltips: false,
          legend: {
            position: "right",
            labels: {
              boxWidth: 10,
              boxHeight: 10,
              textAlign: "left",
              useLineStyle: true,
            },
            onClick: null,
          },
          layout: {
            padding: [10, 5],
          },
          scales: {
            yAxes: [
              {
                stacked: true,
                ticks: {
                  display: false,
                },
                gridLines: {
                  color: "transparent",
                },
              },
            ],
            xAxes: [
              {
                // barPercentage: 0.4,
                display: false,
                stacked: true,
                gridLines: {
                  display: false,
                  drawBorder: false,
                },
              },
            ],
          },
          title: {
            display: false,
          },
          hover: {
            mode: "dataset",
            intersect: false,
          },
        },
      });
    },
  },

  mounted() {
    if (this.MyChartBar != null) {
      this.MyChartBar.destroy();
    }
    this.createChart();
  },
};
</script>

<style lang="scss" scoped>
.bar {
  height: 100%;
  cursor: pointer;
    display: flex;
  justify-content: center;
  align-items: center;
}
.canvas {
  margin: 0px;

}
</style>