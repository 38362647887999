<template>
  <div class="bar">
    <canvas
      class="canvas"
      :id="`myChartBar${id}`"
      height="300px"
      width="600px"
      min-height="300px"
      min-width="600px"
    ></canvas>
  </div>
</template>

<script>
export default {
  name: "MixedSafraConceito",

  props: {
    id: Number,
    eventoPorSafra: Array,
    respMau: String,
  },

  data() {
    return {
      labelSafra: [],
      quantidadeSafra: [],
      representatividadeSafra: [],
      taxaConceito: [],
      maus: "",
      myChartBar: null,
      colors: [
        "rgba(255, 99, 132, 0.4)",
        "rgba(54, 162, 235, 0.4)",
        "rgba(255, 206, 86, 0.4)",
        "rgba(75, 192, 192, 0.4)",
        "rgba(153, 102, 255, 0.4)",
        "rgba(255, 159, 64, 0.4)",
      ],
      hoverColors: [
        "rgba(255, 99, 132, 0.8)",
        "rgba(54, 162, 235, 0.8)",
        "rgba(255, 206, 86, 0.8)",
        "rgba(75, 192, 192, 0.8)",
        "rgba(153, 102, 255, 0.8)",
        "rgba(255, 159, 64, 0.8)",
      ],
      barWidth: 1,
    };
  },

  methods: {
    setLarguraBarra() {
      const numBars = this.labelSafra.length;
      if (numBars < 5) {
        this.barWidth = 0.2
      }
    },

    setDataset() {
      for (const [index, [key, value]] of Object.entries(
        Object.entries(this.eventoPorSafra)
      )) {
        this.labelSafra.push(this.eventoPorSafra[key].Safra);
        this.quantidadeSafra.push(this.eventoPorSafra[key].Quantidade);
        this.representatividadeSafra.push(
          (this.eventoPorSafra[key].Representatividade * 100).toFixed(2)
        );

        for (let val in value.Principal) {
          if (this.respMau == "Valor_0") {
            this.maus = "0";
          }
          if (this.respMau == "Valor_1") {
            this.maus = "1";
          }

          if (value.Principal[val].Valor == this.maus) {
            const contextoLocal = (
              this.eventoPorSafra[key].Principal[val]
                .RepresentatividadeContextoLocal * 100
            ).toFixed(2);

            this.taxaConceito.push(contextoLocal);
          }
          if (
            value.Principal[val].Valor == "NA" &&
            value.Principal.length < 2
          ) {
            const contextoLocal = Number.NA;
            this.taxaConceito.push(contextoLocal);
          }
        }
      }
      this.setLarguraBarra();
    },

    createChart() {
      const ctx = document.getElementById(`myChartBar${this.id}`);
      this.myChartBar = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.labelSafra,
          datasets: [
            
            {
              label: "Frequência de Safras",
              data: this.representatividadeSafra,
              yAxisID: "first-y-axis",
              backgroundColor: "rgba(54, 162, 235, 0.4)",
              spanGaps: 1,
              barPercentage: this.barWidth,
              hoverBackgroundColor: "rgba(54, 162, 235, 0.8)",
              order: 2,
            },
            {
              label: "Frequência de Maus",
              yAxisID: "second-y-axis",
              data: this.taxaConceito,
              borderColor: ["rgba(173, 0, 38, 0.5)"],
              pointHoverBorderColor: ["rgba(173, 0, 38, 1)"],
              backgroundColor: "rgba(173, 0, 38, 0.6)",
              spanGaps: false,
              barPercentage: this.barWidth,
              pointHoverBackgroundColor: "rgba(173, 0, 38, 0.8)",
              type: "line",
              order: 1,
              fill: false,
            },
          ],
        },

        options: {
          maintainAspectRatio: true,
          responsive: false,
          scaleBeginAtZero: true,
          legend: {
            position: "top",
            labels: {
              boxWidth: 10,
              boxHeight: 10,
              textAlign: "left",
              useLineStyle: true,
            },
            onClick: null,
          },
          scales: {
            yAxes: [
              {
                id: "first-y-axis",
                type: "linear",
                position: "right",
                ticks: {
                  stepSize: 5,
                  lineHeight: 0.8,
                  min: 0,
                  callback: function (value, index, ticks) {
                    return value + "%";
                  },
                },
                scaleLabel: {
                  display: true,
                  labelString: "Frequência",
                },
                gridLines: {
                  display: false,
                },
              },
              {
                id: "second-y-axis",
                type: "linear",
                position: "left",
                ticks: {
                  stepSize: 5,
                  lineHeight: 0.8,
                  min: 0,
                  callback: function (value, index, ticks) {
                    return value + "%";
                  },
                },
                scaleLabel: {
                  display: true,
                  labelString: "Taxa de Evento",
                },
              },
            ],
            xAxes: [
              {
                offset: true,
                display: true,
                gridLines: {
                  display: false,
                },
                ticks: {
                  minRotation: 30,
                  lineHeight: 1,
                },
                scaleLabel: {
                  display: true,
                  labelString: "Safra",
                },
              },
            ],
          },
          title: {
            display: false,
          },
          plugins: {
            filler: {
              propagate: true,
            },
          },
          tooltips: {
            mode: "nearest",
            callbacks: {
              label: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label || "";

                if (label) {
                  label += ": ";
                }
                label += tooltipItem.yLabel + "%";
                return label;
              },
            },
          },
          hover: {
            mode: "point",
            intersect: false,
          },
        },
      });
    },
  },

  created() {
    this.setDataset();
  },

  mounted() {
    if (this.myChartBar != null) {
      this.myChartBar.destroy();
    }
    this.createChart();
  },
};
</script>

<style lang="scss" scoped>
.bar {
  cursor: pointer;

  @include mediaQueryMax(medium) {
    width: 100%;
    height: 80%;
  }
}
</style>