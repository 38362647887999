var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inicial-container-safra-amostra"},[_c('h5',{staticClass:"title"},[_vm._v(" Taxa de Evento e Representatividade por Safra e Amostra: "),_c('span',{staticClass:"material-symbols-outlined helpIcon"},[_vm._v(" help "),_c('md-tooltip',{staticClass:"md-layout-item md-size-33 md-small-size-66"},[_vm._v(" Exibe a taxa de Maus em cada "),_c('strong',[_vm._v("Safra")]),_vm._v(" de acordo com a "),_c('strong',[_vm._v("Data de Referência")]),_vm._v(" mapeada, segmentado por amostra, assim como a representatividade de cada amostra por Safra. ")])],1)]),(_vm.liberaSafraDataRef)?_c('div',{staticClass:"chart"},[(
        _vm.varAmostraSelecionada &&
        _vm.varAmostra &&
        !_vm.errorMsgAmostra &&
        _vm.liberaChart &&
        _vm.valido &&
        _vm.eventoPorSafra &&
        _vm.varAmostraQuantidades
      )?_c('div',{staticClass:"chartBox"},[_vm._m(0),_c('StackedSafraAmostra',{key:_vm.varAmostraSelecionada,attrs:{"id":_vm.id + 17,"dataSafra":_vm.eventoPorSafra,"varAmostraQuantidades":_vm.varAmostraQuantidades}})],1):_vm._e(),(!_vm.varAmostra && !_vm.errorMsgAmostra && !_vm.liberaLoad)?_c('div',{staticClass:"chartBoxEmpty",attrs:{"id":"mixedChartDefault"}},[_c('MixedVazio',{attrs:{"id":_vm.id + 8}})],1):_vm._e(),(
        _vm.varAmostraSelecionada &&
        _vm.varAmostra &&
        !_vm.errorMsgAmostra &&
        _vm.liberaChart &&
        _vm.valido &&
        _vm.eventoPorSafra &&
        _vm.varAmostraQuantidades &&
        _vm.eventoPorSafraAmostraComposta
      )?_c('div',{staticClass:"chartBox"},[_vm._m(1),_c('MixedSafraAmostra',{key:_vm.varAmostraSelecionada,attrs:{"id":_vm.id + 20,"data":_vm.eventoPorSafraAmostraComposta}})],1):_vm._e(),(!_vm.varAmostra && _vm.errorMsgAmostra)?_c('md-card',{staticClass:"cardError"},[_c('span',{staticClass:"material-icons icon-error task"},[_vm._v("highlight_off")]),_c('h5',{staticClass:"task errorAlert"},[_vm._v(_vm._s(_vm.errorMsgAmostra))])]):_vm._e(),(!_vm.varAmostra && _vm.errorMsg)?_c('md-card',{staticClass:"cardError"},[_c('span',{staticClass:"material-icons icon-error task"},[_vm._v("highlight_off")]),_c('h5',{staticClass:"task errorAlert"},[_vm._v(_vm._s(_vm.errorMsg))])]):_vm._e(),(_vm.varAmostra && !_vm.errorMsgAmostra && !_vm.valido)?_c('md-card',{staticClass:"cardError"},[_c('span',{staticClass:"material-symbols-outlined icon"},[_vm._v("fmd_bad")]),_c('h5',{staticClass:"task errorAlert"},[_vm._v(" Selecione uma "),_c('strong',[_vm._v("Variável Amostra")]),_vm._v(" válida ")])]):_vm._e()],1):_vm._e(),(!_vm.liberaSafraDataRef)?_c('div',{staticClass:"boxDisable"},[_c('md-card',{staticClass:"cardDisable"},[_c('span',{staticClass:"material-symbols-outlined icon"},[_vm._v("fmd_bad")]),_c('p',{staticClass:"textData errorAlert"},[_vm._v(" As informações relacionadas à "),_c('strong',[_vm._v("Safra")]),_vm._v(" estão "),_c('strong',[_vm._v("desabilitadas")]),_vm._v(" pois não foi mapeada uma "),_c('strong',[_vm._v("Data de Referência")]),_vm._v(" para este Lote. ")])])],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"chartTitle"},[_c('strong',[_vm._v("Amostra por Safra")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"chartTitle"},[_c('strong',[_vm._v("Taxa de Eventos por Safra e Amostra")])])
}]

export { render, staticRenderFns }