<template>
  <div class="header">
    <div class="box">
      <b-tabs
        justified
        card
        pills
        class="fieldTab"
        nav-class="m-0 p-0 border-0 flex-column flex-sm-row"
        nav-wrapper-class="border-0 "
        active-nav-item-class="text-light px-sm-1 px-5"
      >
        <b-tab
          :key="'dyn-tab-' + 0"
          :id="'dyn-tab-' + 0"
          :title="'Amostra Principal'"
          class="cardHeader"
          active
        >
          <InsideBoxAmostra
            :identifier="0"
            :varRespSelecionada="varRespSelecionada"
            :liberaSafraDataRef="liberaSafraDataRef"
            :active="false"
            @valida="valida"
            @variavelAmostra="variavelAmostra"
          ></InsideBoxAmostra>
        </b-tab>
        <b-tab
          :key="'dyn-tab-' + 1"
          :id="'dyn-tab-' + 1"
          :title="'Amostra Desafiante ' + 1"
          class="cardHeader text-dark"
          :title-link-class="'tab-title-class'"
        >
          <InsideBoxAmostra
            :identifier="1"
            :varRespSelecionada="varRespSelecionada"
            :liberaSafraDataRef="liberaSafraDataRef"
            :active="true"
            @valida="valida"
            @variavelAmostra="variavelAmostra"
          ></InsideBoxAmostra>
        </b-tab>
        <b-tab
          :key="'dyn-tab-' + 2"
          :id="'dyn-tab-' + 2"
          :title="'Amostra Desafiante ' + 2"
          class="cardHeader"
          :title-link-class="'tab-title-class'"
        >
          <InsideBoxAmostra
            :identifier="2"
            :varRespSelecionada="varRespSelecionada"
            :liberaSafraDataRef="liberaSafraDataRef"
            :active="true"
            @valida="valida"
            @variavelAmostra="variavelAmostra"
          ></InsideBoxAmostra>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { historicoService } from "@/services";
import Vue from "vue";
import InsideBoxAmostra from "./InsideBoxAmostra";
export default {
  name: "TabsVarAmostra",

  props: {
    varRespSelecionada: String,
    liberaSafraDataRef: Boolean,
  },
  components: {
    InsideBoxAmostra,
  },
  data() {
    return {
      identificador: this.$route.params.id,
      varAmostra: null,
      varAmostraSelecionada: "",
      trainSampleSelecionada: "",
      errorMsg: "",
      identifier: null,
      valid: { 0: false },
      trainSamples: {},
    };
  },
  methods: {
    variavelAmostra(a) {
      this.varAmostraSelecionada = a;
      this.$emit("variavelAmostra", this.varAmostraSelecionada);
    },

    valida(id, v, varAmos, trainSample) {
      if (trainSample == "") delete this.trainSamples[id];
      else {
        this.trainSamples[id] = {
          amostraVar: varAmos,
          trainVar: trainSample,
        };
      }
      this.valid[id] = v;
      for (let i in this.valid) {
        if (this.valid[i] != true) {
          this.$emit("validaAndRecebe", false, this.trainSamples);
          return;
        }
      }

      this.$emit("validaAndRecebe", true, this.trainSamples);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  margin: 0;
  @include mediaQueryMax(small) {
    width: 100%;
  }
}
.fieldTab {
  min-height: 450px;
  max-height: 750px;
  display: flex;
  justify-content: flex-start;
  margin: 0% !important;
  padding: 0% !important;
  flex-direction: column !important;
  width: 100% !important;

  @include mediaQueryMax(medium) {
    max-height: 2000px;
    flex-direction: column;
    padding: 0.2rem 1.5rem 0.2rem 1.5rem;
  }

  @include mediaQueryMax(small) {
    max-height: 2000px;
    flex-direction: column;
    padding: 0.2rem 1.5rem 0.2rem 1.5rem;
  }
}
.box {
  background-color: #f9f7ff;
  min-height: 100%;
  flex-direction: row;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
}

.cardHeader {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 !important;
  margin: 0 0 0 0px !important;
}
.nav-editing {
  display: flex;
  flex-direction: row;
  @include mediaQueryMax(small) {
    flex-direction: column;
  }
}
</style>