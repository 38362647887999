var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.active)?_c('div',{staticClass:"activateTab"},[_c('b-form-checkbox',{staticClass:"checkbox",attrs:{"id":'checkbox-1' + _vm.identifier,"name":'checkbox-1' + _vm.identifier,"value":"accepted","unchecked-value":"not_accepted"},on:{"change":_vm.checkboxChecked},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('strong',{staticClass:"checkText"},[_vm._v(" Ative para informar uma Amostra Desafiante. ")])])],1):_vm._e(),_c('div',{staticClass:"cardBox"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title"},[_c('h5',{staticClass:"titleText"},[_vm._v(" Selecione a coluna referente à Variável Amostra: ")]),_c('span',{staticClass:"material-symbols-outlined helpIcon"},[_vm._v(" help "),_c('md-tooltip',{staticClass:"md-layout-item md-size-33 md-small-size-66 text-left"},[_vm._v(" Selecione a coluna referente à "),_c('strong',[_vm._v("Variável Amostra")]),_vm._v(", que será utilizada como plano amostral no processo de modelagem."),_c('br'),_c('strong',[_vm._v("Validações de coluna:")]),_vm._v(" "),_c('br'),_c('li',[_vm._v(" A variável selecionada não deve possuir mais de 23 tipos de amostra e não pode ter valores ausentes. ")]),_c('li',[_vm._v(" Se uma amostra cruzada com uma Variável Resposta tiver NA, todas devem conter apenas NA. ")]),_c('li',[_vm._v(" Quando não tiver NA, deve conter 0 e 1 e não apenas só 0 ou só 1. ")])])],1)]),_c('md-field',{staticClass:"selectCol"},[_c('label',{attrs:{"for":"varAmostraSelecionada"}},[_vm._v("Selecione a Variável Amostra* ")]),_c('md-select',{attrs:{"name":"varAmostraSelecionada","id":'varAmostraSelecionada' + _vm.identifier,"disabled":_vm.active && _vm.status == 'not_accepted',"placeholder":_vm.varAmostraMemory[_vm.identifier] != undefined
              ? _vm.varAmostraMemory[_vm.identifier] + ' (Selecionado)'
              : _vm.optionsListAmostra.length <= 1
              ? 'Nenhuma coluna identificada'
              : 'Selecione a variavel Amostra'},on:{"input":_vm.verificaVariavelAmostra},model:{value:(_vm.varAmostraSelecionada),callback:function ($$v) {_vm.varAmostraSelecionada=$$v},expression:"varAmostraSelecionada"}},[_c('div',{staticClass:"textSearch"},[_c('md-input',{staticClass:"textSearchInput",attrs:{"placeholder":"Procure uma coluna"},model:{value:(_vm.searchValueAmostra),callback:function ($$v) {_vm.searchValueAmostra=$$v},expression:"searchValueAmostra"}})],1),_vm._l((_vm.optionsListAmostra),function(item){return _c('md-option',{key:item,attrs:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])})],2)],1),_c('div',{staticClass:"trainSampleBox"},[_c('div',{staticClass:"title"},[_vm._m(0),_c('span',{staticClass:"material-symbols-outlined helpIcon"},[_vm._v(" help "),_c('md-tooltip',{staticClass:"md-layout-item md-size-33 md-small-size-66"},[_vm._v(" Selecione a "),_c('strong',[_vm._v("Amostra de Treino")]),_vm._v(", que será utilizada no desenvolvimento do modelo."),_c('br')])],1)]),_c('md-field',{staticClass:"selectCol",style:(!(
              _vm.varAmostraQuantidades &&
              _vm.varAmostraSelecionada &&
              _vm.amostraValidada &&
              !_vm.errorMsgAmostra &&
              !_vm.errorMsg
            )
              ? 'cursor: not-allowed'
              : ''),attrs:{"disabled":!(
              _vm.varAmostraQuantidades &&
              _vm.varAmostraSelecionada &&
              _vm.amostraValidada &&
              !_vm.errorMsgAmostra &&
              !_vm.errorMsg
            )}},[_c('label',{attrs:{"for":"trainSampleSelecionada"}},[_vm._v("Selecione a Amostra de Treino* ")]),(
              _vm.varAmostraQuantidades &&
              _vm.varAmostraSelecionada &&
              _vm.amostraValidada &&
              !_vm.errorMsgAmostra &&
              !_vm.errorMsg
            )?_c('md-select',{attrs:{"name":"trainSampleSelecionada","id":'trainSampleSelecionada' + _vm.identifier,"placeholder":"Selecione a Amostra de Treino"},on:{"input":_vm.validaAmostraTrainSample},model:{value:(_vm.trainSampleSelecionada),callback:function ($$v) {_vm.trainSampleSelecionada=$$v},expression:"trainSampleSelecionada"}},_vm._l((Object.keys(this.varAmostraQuantidades)),function(item){return _c('md-option',{key:item,attrs:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])}),1):_vm._e()],1)],1)],1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"charts"},[(
            _vm.varAmostraSelecionada &&
            _vm.varAmostraQuantidades &&
            _vm.amostraValidada &&
            !_vm.errorMsgAmostra
          )?_c('div',{staticClass:"barChart"},[_vm._m(1),(_vm.varAmostraQuantidades && !_vm.errorMsgAmostra && !_vm.liberaLoad)?_c('VarAmostraBar',{key:_vm.keyBar,attrs:{"label":'Valores da Variável Amostra',"data":Object.values(this.varAmostraQuantidades),"labels":Object.keys(this.varAmostraQuantidades),"variavel":_vm.varAmostraQuantidades,"id":_vm.identifier + 14}}):_vm._e()],1):_vm._e(),(!_vm.varAmostra && !_vm.errorMsgAmostra && !_vm.varAmostraSelecionada)?_c('div',{staticClass:"barChart",attrs:{"id":"barChartDefault"}},[_c('BarVazio',{attrs:{"id":5 + _vm.identifier}})],1):_vm._e(),(
            _vm.trainSampleSelecionada &&
            _vm.varAmostraQuantidades &&
            _vm.varAmostraSelecionada &&
            _vm.amostraValidada &&
            !_vm.liberaLoad
          )?_c('div',{staticClass:"pieBox"},[_c('p',{staticClass:"chartTitle"},[_c('strong',[_vm._v(_vm._s(_vm.trainSampleSelecionada))])]),_c('PieIntegrAmostra',{key:_vm.trainSampleSelecionada,attrs:{"label":_vm.trainSampleSelecionada,"data":Object.values(_vm.varAmostra[_vm.trainSampleSelecionada]),"labels":Object.keys(_vm.varAmostra[_vm.trainSampleSelecionada]),"variavel":_vm.varAmostra[_vm.trainSampleSelecionada],"id":_vm.identifier + 23}})],1):_vm._e(),(!_vm.liberaLoad && !_vm.trainSampleSelecionada)?_c('div',{staticClass:"pieBox",attrs:{"id":'PieChartDefault' + _vm.identifier}},[_c('PieVazio',{attrs:{"id":11 + _vm.identifier}})],1):_vm._e()])])]),_c('div',{staticClass:"cardBoxSafra"},[_c('safraAmostra',{ref:"safraAmostra",attrs:{"id":_vm.identifier,"liberaSafraDataRef":_vm.liberaSafraDataRef,"trainSampleSelecionada":_vm.trainSampleSelecionada,"varAmostraSelecionada":_vm.varAmostraSelecionada,"varAmostra":_vm.varAmostra,"errorMsgAmostra":_vm.errorMsgAmostra,"errorMsg":_vm.errorMsg,"valido":_vm.valido,"liberaLoad":_vm.liberaLoad,"varAmostraQuantidades":_vm.varAmostraQuantidades}})],1),(_vm.liberaLoad)?_c('div',{staticClass:"spinnerBox text-center"},[_c('Loading',{staticClass:"spinner"})],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"titleText"},[_c('strong',[_vm._v(" Selecione a Amostra de Treino para desenvolvimento do modelo: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"chartTitle"},[_c('strong',[_vm._v("Representatividade por Amostra")])])
}]

export { render, staticRenderFns }