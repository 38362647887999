<template>
  <div class="bar">
    <canvas
      class="canvas"
      :id="`myChartBar${id}`"
      height="180"
      width="180"
    ></canvas>
  </div>
</template>

<script>
import { useSkynetStore } from "@/stores/skynet";
import { mapActions } from "pinia";

export default {
  name: "VarAmostraBar",
  props: {
    data: Array,
    label: String,
    labels: Array,
    position: String,
    variavel: Object,
    id: Number,
  },
  data() {
    return {
      lab: null,
      sum: 0,
      dataSetsCustom: [],
      MyChartBar: null,
      colors: [
        "rgb(67, 99, 216, 0.4)",
        "rgb(245, 130, 49, 0.4)",
        "rgb(145, 30, 180, 0.4)",
        "rgb(70, 240, 240, 0.4)",
        "rgb(240, 50, 230, 0.4)",
        "rgb(188, 246, 12, 0.4)",
        "rgb(250, 190, 190, 0.4)",
        "rgb(0, 128, 128, 0.4)",
        "rgb(230, 190, 255, 0.4)",
        "rgb(154, 99, 36, 0.4)",
        "rgb(255, 250, 200, 0.4)",
        "rgb(128, 0, 0, 0.4)",
        "rgb(170, 255, 195, 0.4)",
        "rgb(128, 128, 0, 0.4)",
        "rgb(255, 216, 177, 0.4)",
        "rgb(0, 0, 117, 0.4)",
        "rgb(128, 128, 128, 0.4)",
        "rgb(255, 225, 25, 0.4)",
        "rgb(175, 255, 0, 0.4)",
        "rgb(175, 175, 0, 0.4)",
        "rgb(175, 0, 215, 0.4)",
        "rgb(135, 215, 255, 0.4)",
        "rgb(95, 0, 0, 0.4)",
        "rgb(0, 175, 255, 0.4)",
        "rgb(255, 0, 255, 0.4)",
        "rgb(0, 0, 95, 0.4)",
        "rgb(95, 0, 95, 0.4)",
        "rgb(230, 25, 75, 0.4)",
        "rgb(60, 180, 75, 0.4)",
        ,
      ],
      hoverColors: [
        "rgb(67, 99, 216, 0.8)",
        "rgb(245, 130, 49, 0.8)",
        "rgb(145, 30, 180, 0.8)",
        "rgb(70, 240, 240, 0.8)",
        "rgb(240, 50, 230, 0.8)",
        "rgb(188, 246, 12, 0.8)",
        "rgb(250, 190, 190, 0.8)",
        "rgb(0, 128, 128, 0.8)",
        "rgb(230, 190, 255, 0.8)",
        "rgb(154, 99, 36, 0.8)",
        "rgb(255, 250, 200, 0.8)",
        "rgb(128, 0, 0, 0.8)",
        "rgb(170, 255, 195, 0.8)",
        "rgb(128, 128, 0, 0.8)",
        "rgb(255, 216, 177, 0.8)",
        "rgb(0, 0, 117, 0.8)",
        "rgb(128, 128, 128, 0.8)",
        "rgb(255, 225, 25, 0.8)",
        "rgb(175, 255, 0, 0.8)",
        "rgb(175, 175, 0, 0.8)",
        "rgb(175, 0, 215, 0.8)",
        "rgb(135, 215, 255, 0.8)",
        "rgb(95, 0, 0, 0.8)",
        "rgb(0, 175, 255, 0.8)",
        "rgb(255, 0, 255, 0.8)",
        "rgb(0, 0, 95, 0.8)",
        "rgb(95, 0, 95, 0.8)",
        "rgb(230, 25, 75, 0.8)",
        "rgb(60, 180, 75, 0.8)",
        ,
      ],
    };
  },

  methods: {
    formatLabel() {
      let val = Object.values(this.variavel);
      this.sum = val.reduce((a, b) => a + b, 0);
    },
    setDataset() {
      this.dataSetsCustom = [];
      for (const [index, [key, value]] of Object.entries(
        Object.entries(this.variavel)
      )) {
        this.dataSetsCustom.push({
          label: [key + " (" + ((value / this.sum) * 100).toFixed(1) + "%)"],
          data: [value],
          backgroundColor: [this.colors[index]],
          hoverBackgroundColor: [this.hoverColors[index]],
          borderWidth: 0,
          barThickness: 70,
          maxBarThickness: 100,
        });
      }
    },
    createChart() {
      const ctx = document.getElementById(`myChartBar${this.id}`);
      this.MyChartBar = new Chart(ctx, {
        type: "bar",
        data: {
          labels: [""],
          datasets: this.dataSetsCustom,
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          scaleBeginAtZero: true,
          legend: {
            position: "right",
            labels: {
              boxWidth: 10,
              boxHeight: 10,
              textAlign: "left",
              useLineStyle: true,
            },
            onClick: null,
          },
          scales: {
            yAxes: [
              {
                stacked: true,
                ticks: {
                  display: false,
                },
                gridLines: {
                  color: "transparent",
                },
              },
            ],
            xAxes: [
              {
                display: false,
                stacked: true,
                gridLines: {
                  display: false,
                  drawBorder: false,
                },
              },
            ],
          },
          title: {
            display: false,
          },
          hover: {
            mode: "dataset",
            intersect: false,
          },
        },
      });
    },
    ...mapActions(useSkynetStore, ["setVarAmostraQtd"]),
  },

  created() {
    this.formatLabel();
    this.setDataset();
    this.setVarAmostraQtd(this.variavel);
  },

  mounted() {
    if (this.MyChartBar != null) {
      this.MyChartBar.destroy();
    }
    this.createChart();
  },
};
</script>

<style lang="scss" scoped>
.bar {
  width: 100%;
  cursor: pointer;
}
.canvas {
  // width: 250px;
  margin: 0px;
}
</style>