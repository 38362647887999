<template>
  <div>
    <div class="activateTab" v-if="active">
      <b-form-checkbox
        class="checkbox"
        :id="'checkbox-1' + identifier"
        v-model="status"
        :name="'checkbox-1' + identifier"
        value="accepted"
        unchecked-value="not_accepted"
        @change="checkboxChecked"
      >
        <strong class="checkText">
          Ative para informar uma Amostra Desafiante.
        </strong>
      </b-form-checkbox>
    </div>
    <div class="cardBox">
      <div class="left">
        <div class="title">
          <h5 class="titleText">
            Selecione a coluna referente à Variável Amostra:
          </h5>
          <span class="material-symbols-outlined helpIcon">
            help
            <md-tooltip
              class="md-layout-item md-size-33 md-small-size-66 text-left"
            >
              Selecione a coluna referente à <strong>Variável Amostra</strong>,
              que será utilizada como plano amostral no processo de
              modelagem.<br />
              <strong>Validações de coluna:</strong> <br />
              <li>
                A variável selecionada não deve possuir mais de 23 tipos de
                amostra e não pode ter valores ausentes.
              </li>
              <li>
                Se uma amostra cruzada com uma Variável Resposta tiver NA, todas
                devem conter apenas NA.
              </li>
              <li>
                Quando não tiver NA, deve conter 0 e 1 e não apenas só 0 ou só
                1.
              </li>
            </md-tooltip>
          </span>
        </div>
        <md-field class="selectCol">
          <label for="varAmostraSelecionada"
            >Selecione a Variável Amostra*
          </label>
          <md-select
            name="varAmostraSelecionada"
            :id="'varAmostraSelecionada' + identifier"
            @input="verificaVariavelAmostra"
            v-model="varAmostraSelecionada"
            :disabled="active && status == 'not_accepted'"
            :placeholder="
              varAmostraMemory[identifier] != undefined
                ? varAmostraMemory[identifier] + ' (Selecionado)'
                : optionsListAmostra.length <= 1
                ? 'Nenhuma coluna identificada'
                : 'Selecione a variavel Amostra'
            "
          >
            <div class="textSearch">
              <md-input
                v-model="searchValueAmostra"
                class="textSearchInput"
                placeholder="Procure uma coluna"
              >
              </md-input>
            </div>
            <md-option
              v-for="item in optionsListAmostra"
              :key="item"
              :value="item"
            >
              {{ item }}
            </md-option>
          </md-select>
        </md-field>

        <div class="trainSampleBox">
          <div class="title">
            <h5 class="titleText">
              <strong>
                Selecione a Amostra de Treino para desenvolvimento do modelo:
              </strong>
            </h5>
            <span class="material-symbols-outlined helpIcon">
              help
              <md-tooltip class="md-layout-item md-size-33 md-small-size-66">
                Selecione a <strong>Amostra de Treino</strong>, que será
                utilizada no desenvolvimento do modelo.<br />
              </md-tooltip>
            </span>
          </div>
          <md-field
            class="selectCol"
            :style="
              !(
                varAmostraQuantidades &&
                varAmostraSelecionada &&
                amostraValidada &&
                !errorMsgAmostra &&
                !errorMsg
              )
                ? 'cursor: not-allowed'
                : ''
            "
            :disabled="
              !(
                varAmostraQuantidades &&
                varAmostraSelecionada &&
                amostraValidada &&
                !errorMsgAmostra &&
                !errorMsg
              )
            "
          >
            <label for="trainSampleSelecionada"
              >Selecione a Amostra de Treino*
            </label>
            <md-select
              v-if="
                varAmostraQuantidades &&
                varAmostraSelecionada &&
                amostraValidada &&
                !errorMsgAmostra &&
                !errorMsg
              "
              name="trainSampleSelecionada"
              :id="'trainSampleSelecionada' + identifier"
              v-model="trainSampleSelecionada"
              @input="validaAmostraTrainSample"
              placeholder="Selecione a Amostra de Treino"
            >
              <md-option
                v-for="item in Object.keys(this.varAmostraQuantidades)"
                :key="item"
                :value="item"
              >
                {{ item }}
              </md-option>
            </md-select>
          </md-field>
        </div>
      </div>
      <div class="right">
        <div class="charts">
          <div
            class="barChart"
            v-if="
              varAmostraSelecionada &&
              varAmostraQuantidades &&
              amostraValidada &&
              !errorMsgAmostra
            "
          >
            <p class="chartTitle">
              <strong>Representatividade por Amostra</strong>
            </p>
            <VarAmostraBar
              v-if="varAmostraQuantidades && !errorMsgAmostra && !liberaLoad"
              :label="'Valores da Variável Amostra'"
              :data="Object.values(this.varAmostraQuantidades)"
              :labels="Object.keys(this.varAmostraQuantidades)"
              :variavel="varAmostraQuantidades"
              :id="identifier + 14"
              :key="keyBar"
            />
          </div>
          <div
            class="barChart"
            id="barChartDefault"
            v-if="!varAmostra && !errorMsgAmostra && !varAmostraSelecionada"
          >
            <BarVazio :id="5 + identifier" />
          </div>

          <div
            class="pieBox"
            v-if="
              trainSampleSelecionada &&
              varAmostraQuantidades &&
              varAmostraSelecionada &&
              amostraValidada &&
              !liberaLoad
            "
          >
            <p class="chartTitle">
              <strong>{{ trainSampleSelecionada }}</strong>
            </p>
            <PieIntegrAmostra
              :label="trainSampleSelecionada"
              :data="Object.values(varAmostra[trainSampleSelecionada])"
              :labels="Object.keys(varAmostra[trainSampleSelecionada])"
              :variavel="varAmostra[trainSampleSelecionada]"
              :id="identifier + 23"
              :key="trainSampleSelecionada"
            />
          </div>
          <div
            class="pieBox"
            :id="'PieChartDefault' + identifier"
            v-if="!liberaLoad && !trainSampleSelecionada"
          >
            <PieVazio :id="11 + identifier" />
          </div>
        </div>
      </div>
    </div>
    <div class="cardBoxSafra">
      <safraAmostra
        ref="safraAmostra"
        :id="identifier"
        :liberaSafraDataRef="liberaSafraDataRef"
        :trainSampleSelecionada="trainSampleSelecionada"
        :varAmostraSelecionada="varAmostraSelecionada"
        :varAmostra="varAmostra"
        :errorMsgAmostra="errorMsgAmostra"
        :errorMsg="errorMsg"
        :valido="valido"
        :liberaLoad="liberaLoad"
        :varAmostraQuantidades="varAmostraQuantidades"
      />
    </div>
    <div class="spinnerBox text-center" v-if="liberaLoad">
      <Loading class="spinner" />
    </div>
  </div>
</template>

<script>
import { historicoService } from "@/services";
import { useSkynetStore } from "@/stores/skynet";
import { useSkynetMemoryStore } from "@/stores/skynetMemory";
import { mapState, mapActions } from "pinia";
import VarAmostraBar from "./Charts/VarAmostraBar.vue";
import BarVazio from "@/components/Integracao/Charts/BarVazio";
import safraAmostra from "@/components/Integracao/safraAmostra";
import PieVazio from "@/components/Integracao/Charts/PieVazio";
import PieIntegrAmostra from "@/components/Integracao/Charts/PieIntegrAmostra";
import Loading from "@/components/Loading";
import Swal from "sweetalert2";

export default {
  name: "InsideBoxAmostraAmostra",

  components: {
    safraAmostra,
    VarAmostraBar,
    PieIntegrAmostra,
    Loading,
    PieVazio,
    BarVazio,
  },

  props: {
    varRespSelecionada: String,
    identifier: Number,
    active: Boolean,
    liberaSafraDataRef: Boolean,
  },

  data() {
    return {
      status: "not_accepted",
      identificador: this.$route.params.id,
      varAmostra: null,
      varAmostraSelecionada: "",
      varAmostSelecionadaBef: "",
      trainSampleSelecionada: "",
      errorMsgAmostra: "",
      errorMsg: "",
      trainSampleOptions: [],
      amostraValidada: false,
      varAmostraQuantidades: null,
      keyBar: "",
      searchValueAmostra: "",
      valido: false,
      liberaLoad: false,
    };
  },
  methods: {
    validateAndShare(identf, valid, varAmos, trainSample) {
      this.$emit("valida", identf, valid, varAmos, trainSample);
    },

    async verificaVariavelAmostra() {
      if (this.varAmostraSelecionada == "") return;
      this.varAmostra = null;
      this.liberaLoad = true;
      this.trainSampleSelecionada = "";
      this.validateAndShare(this.identifier, false, "", "");
      this.$emit("variavelAmostra", this.varAmostraSelecionada);
      this.updateCol(this.varAmostraSelecionada, this.varAmostSelecionadaBef);
      this.varAmostSelecionadaBef = this.varAmostraSelecionada;

      const objetoHistorico = {
        identificacao_execucao: this.identificador,
        coluna_principal: this.varAmostraSelecionada,
        coluna_secundaria: this.varRespSelecionada,
        limite_contagem: 50,
      };
      const query = new URLSearchParams(objetoHistorico);
      try {
        this.varAmostra =
          await historicoService.recuperaVariavelAmostraComposta(
            query.toString()
          );
        this.amostraValidada = this.validaVarAmostra();
        this.verificaQuantidadeAmostra();
        this.errorMsgAmostra = null;
      } catch (error) {
        this.liberaLoad = false;
        this.varAmostra = null;
        this.errorMsgAmostra = error.toString();
      }
    },

    async verificaQuantidadeAmostra() {
      const objetoHistorico = {
        identificacao_execucao: this.identificador,
        coluna_principal: this.varAmostraSelecionada,
        limite_contagem: 50,
      };
      const query = new URLSearchParams(objetoHistorico);
      try {
        this.varAmostraQuantidades =
          await historicoService.recuperaVariavelAmostra(query.toString());
        this.keyBar = this.varAmostraSelecionada;
        this.errorMsg = null;
        this.liberaLoad = false;
      } catch (error) {
        this.varAmostraQuantidades = null;
        this.errorMsg = error.toString();
        this.liberaLoad = false;
      }
    },

    checkboxChecked() {
      this.varAmostraSelecionada = "";
      this.trainSampleSelecionada = "";
      this.setVarAmostra(this.varAmostraSelecionada, this.identifier);
    
      this.setVarAmostraTreino(this.trainSampleSelecionada, this.identifier);

      if (this.status == "accepted") {
        this.validateAndShare(
          this.identifier,
          false,
          this.varAmostraSelecionada,
          this.trainSampleSelecionada
        );
      } else {
        if (this.varAmostSelecionadaBef != "")
          this.addCol(this.varAmostSelecionadaBef);
        if (this.varAmostraMemory[this.identifier] != undefined)
          this.deleteMemoryAmostra(this.identifier);
        this.validateAndShare(
          this.identifier,
          true,
          this.varAmostraSelecionada,
          this.trainSampleSelecionada
        );
      }
      this.varAmostSelecionadaBef = "";
    },

    validaVarAmostra() {
      if (
        Object.keys(this.varAmostra).length < 24 &&
        this.varAmostra["NA"] == undefined &&
        this.varAmostra[""] == undefined
      ) {
        this.setVarAmostra(this.varAmostraSelecionada, this.identifier);
        this.$refs.safraAmostra.recuperaEventoPorSafra();
        this.$refs.safraAmostra.recuperaEventoPorSafraComposto();

        this.valido = true;
        return true;
      } else {
        this.valido = false;
        Swal.fire({
          icon: "error",
          title: "Variável Amostra Inválida",
          text: "Os valores da Variável Amostra devem ser menor do que 23 e não devem conter 'NA' ou vazio.",
          buttonsStyling: false,
          customClass: {
            confirmButton: "md-button md-warning",
          },
        });
        return false;
      }
    },

    validaAmostraTrainSample() {
      if (this.trainSampleSelecionada == "") return;

      for (let i in this.varAmostra) {
        if (
          this.varAmostra[i].NA != undefined &&
          Object.keys(this.varAmostra[i]).length != 1
        ) {
          Swal.fire({
            icon: "error",
            title: "Amostra de Treino Inválida",
            html:
              "Quando a Amostra de Treino conter valores 'NA', todos deverão ser 'NA'. <br/>" +
              "<strong>Se um dos valores das Amostras de Treino for inválido, a Amostra é inválida.</strong>",
            buttonsStyling: false,
            customClass: {
              confirmButton: "md-button md-warning",
            },
          });
          this.validateAndShare(
            this.identifier,
            false,
            this.varAmostraSelecionada,
            this.trainSampleSelecionada
          );
          return;
        } else if (
          this.varAmostra[i].NA == undefined &&
          (this.varAmostra[i]["0"] < 2 || this.varAmostra[i]["1"] < 2)
        ) {
          Swal.fire({
            icon: "error",
            title: "Amostra de Treino Inválida",
            html:
              "Quando a Amostra de Treino conter valores 'NA', todos deverão ser 'NA'. <br/>" +
              "<strong>Se um dos valores das Amostras de Treino for inválido, a Amostra é inválida.</strong>",
            buttonsStyling: false,
            customClass: {
              confirmButton: "md-button md-warning",
            },
          });
          this.validateAndShare(
            this.identifier,
            false,
            this.varAmostraSelecionada,
            this.trainSampleSelecionada
          );
          return;
        }
      }
      this.validateAndShare(
        this.identifier,
        true,
        this.varAmostraSelecionada,
        this.trainSampleSelecionada
      );
      this.setVarAmostraTreino(this.trainSampleSelecionada, this.identifier);
    },

    async setMemoryData() {
      this.status = "accepted";
      this.varAmostraSelecionada = this.varAmostraMemory[this.identifier];

      await this.verificaVariavelAmostra();
      this.trainSampleSelecionada =
        this.varAmostraTreinoMemory[this.identifier];
      this.setVarAmostraTreino(undefined, this.identifier);
      this.validaAmostraTrainSample();
    },

    ...mapActions(useSkynetStore, ["updateCol", "addCol"]),
    ...mapActions(useSkynetMemoryStore, [
      "setVarAmostra",
      "setVarAmostraTreino",
      "deleteMemoryAmostra",
    ]),
  },
  computed: {
    ...mapState(useSkynetStore, ["availableCols"]),
    ...mapState(useSkynetMemoryStore, [
      "varAmostraMemory",
      "varAmostraTreinoMemory",
    ]),

    optionsListAmostra() {
      if (this.searchValueAmostra.trim().length > 0) {
        return this.availableCols.filter((column) =>
          column.toLowerCase().includes(this.searchValueAmostra.trim())
        );
      }
      return this.availableCols;
    },
  },
  created() {
    if (this.varAmostraMemory[this.identifier] != undefined) {
      this.setMemoryData();
      this.searchValueAmostra = "";
    }
  },
};
</script>

<style lang="scss" scoped>
.left {
  width: 50%;
  min-height: 250px !important;
  display: flex;
  flex-direction: column;
  margin: 5px !important;
  @include mediaQueryMax(medium) {
    width: 100%;
    min-height: 290px !important;
    max-height: 370px !important;
  }

  @include mediaQueryMax(small) {
    width: 100%;
    min-height: 290px !important;
    max-height: 370px !important;
  }
}
.right {
  width: 50%;
  min-height: 250px !important;
  display: flex;
  flex-direction: row;
  margin: 5px !important;

  @include mediaQueryMax(medium) {
    width: 100%;
    min-height: 400px !important;
  }
  @include mediaQueryMax(small) {
    width: 100%;
    min-height: 400px !important;
  }
}
.trainSampleBox {
  display: flex;
  flex-direction: column;
}
.title {
  margin: 10px 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: normal;
}

.titleText {
  display: flex;
  word-wrap: break-word;
  font-size: 1rem;
  line-height: 2rem;
  margin: 0;
}
.helpIcon {
  cursor: pointer;
  color: #209cee;
  font-size: 1.5rem;
  margin-left: 0.3rem;
}

.charts {
  display: flex;
  width: 100%;
  padding: 10px;
  @include mediaQueryMax(medium) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}
.barChart {
  width: 40%;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @include mediaQueryMax(medium) {
    width: 60%;
    margin-right: 1.3rem;
  }
  @include mediaQueryMax(small) {
    margin-right: 1.3rem;
    width: 100%;
  }
}
.cardBox {
  display: flex;
  width: 100%;
  min-height: 250px;
  flex: 1;
  flex-direction: row;
  // padding: 0.2rem;

  @include mediaQueryMax(small) {
    flex-direction: column;
    max-height: 900px;
  }
}

.cardBoxSafra {
  display: flex;
  width: 100%;
  min-height: 200px;
  // max-height: 450px;
  flex: 1;
  flex-direction: row;
  padding: 0.2rem;

  @include mediaQueryMax(small) {
    flex-direction: column;
    max-height: 900px;
  }
}

.selectCol {
  width: 70%;
  height: 3rem;
  margin-bottom: 0rem;
  margin-top: 1rem;
  @include mediaQueryMax(medium) {
    // margin-bottom: 7rem;
  }
  @include mediaQueryMax(small) {
    margin-bottom: 0rem;
    width: 100%;
  }
}
.textSearch {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}
.textSearchInput {
  display: flex;
  width: 95%;
  height: 35px;
  border-width: 0px 0px 0.1rem 0px;
  font-size: small;
}
.pieBox {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 1rem;

  @include mediaQueryMax(medium) {
    flex-direction: column;
    width: 90%;
    margin: 1rem 0;
  }
  @include mediaQueryMax(small) {
    flex-direction: column;
    margin-right: 1rem;
    width: 100%;
  }
}

.activateTab {
  width: 100%;
  min-height: 1rem;
  font-size: 0.9rem;
  @include flex-center(row);
  justify-content: flex-start !important;

  @include mediaQueryMax(small) {
    width: 100%;
  }
}

.spinner {
  // width: 20px;
  display: flex;
  z-index: 100;
  // position: absolute;
  // top: 50%;
  // left: 40%;
}

.checkbox {
  width: 100%;
  display: flex;
  margin: 0.2rem 1rem;
  align-items: flex-start;
}
.checkText {
  line-height: 1.5rem;
}

.spinnerBox {
  display: flex;
  position: absolute;
  left: 50%;
  bottom: 30%;
  z-index: 10;
}
</style>