<template>
  <div class="pieVazio">
    <canvas
      class="canvas"
      :id="`myChart${id}`"
      height="200px"
    ></canvas>
  </div>
</template>

<script>
export default {
  name: "PieVazio",
  props: {
    id: Number,
  },
  data() {
    return {};
  },

  methods: {
    createChart() {
      const ctx = document.getElementById(`myChart${this.id}`);
      const myChart = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: ["NA"],
          datasets: [
            {
              label: "NA",
              data: [2, 6, 10, 15],
              backgroundColor: [
                "rgb(0, 0, 0, 0.2)",
                "rgb(51, 51, 51, 0.2)",
                "rgb(1, 1, 1, 0.2)",
                "rgb(52, 52, 52, 0.2)",
              ],
              borderWidth: 0,
            },
          ],
        },
        options: {
          responsive: false,
          scaleBeginAtZero: true,
          maintainAspectRatio: true,
          hover: false,
          tooltips: false,
          legend: {
            position: "top",
            labels: {
              boxWidth: 10,
              boxHeight: 10,
              useLineStyle: true,
            },
            onClick: null,
          },
          layout: {
            padding: 0,
          },
          scales: {
            yAxes: {
              ticks: {
                display: false,
              },
              grid: {
              },
            },
          },
          title: {
            display: false,
          },
        },
      });
    },
  },

  created() {
  },

  mounted() {
    if (this.myChart != null) {
      this.myChart.destroy();
    }
    this.createChart();
  },
};
</script>

<style lang="scss" scoped>
.pieVazio {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}

.canvas {
  margin: 0px;
}
</style>