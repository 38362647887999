<template>
  <div
    class="opcaoLinguagemBox"
    :style="
      clicked
        ? 'box-shadow: #209cee 0px 3px 8px;background-color:#209cee;color:white'
        : 'box-shadow: gray 0px 1px 4px;background-color:white;color:#209cee'
    "
    @click="selecaoAbordagem(abordagem)"
  >
    <div
      class="imgOpcao"
      :style="
        clicked
          ? 'border-bottom: 1px solid white;'
          : 'border-bottom: 1px solid #209cee;'
      "
    >
      <img
        :src="clicked ? logoLight : logo"
        class="logosAbordagemIntegracao"
        alt="python logo"
      />
    </div>
    <span class="textOpcaoAbordagem">{{ description }}</span>
  </div>
</template>

<script>
import { useSkynetMemoryStore } from "@/stores/skynetMemory";
import { mapState, mapActions } from "pinia";

export default {
  name: "OpcaoAbordagem",

  components: {},

  props: {
    logo: String,
    logoLight: String,
    abordagem: String,
    clicked: Boolean,
    description: String,
    abordagemMemory: Object,

  },

  data() {
    return {};
  },
  methods: {
    selecaoAbordagem(abordagem) {
      this.$emit("recebeAbordagem", abordagem);

    },
  },
  computed: {
    // ...mapState(useSkynetMemoryStore, ["abordagemMemory"]),
  },
  mounted() {
    if (this.abordagemMemory[this.abordagem] == true) {
      this.selecaoAbordagem(this.abordagem);
    }
  },
};
</script>

<style lang="scss" scoped>
.opcaoLinguagemBox {
  width: 5.5rem;
  height: 5.5rem;
  background-color: #209cee;
  padding: 0.5rem;
  @include flex-center(column);
  cursor: pointer;
  border-radius: 10px;
}
.imgOpcao {
  width: 100%;
  height: 3.7rem;
  // background-color: red;
  border-bottom: 1px solid lightgray;

  padding: 0.3rem;
}
.logosAbordagemIntegracao {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.textOpcaoAbordagem {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 0.7rem;
  font-weight: 500;
  // color: white;
}
</style>