var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inicial-container-safra-conceito"},[_c('h5',{staticClass:"title"},[_vm._v(" Taxa de Evento por Safra: "),_c('span',{staticClass:"material-symbols-outlined helpIcon"},[_vm._v(" help "),_c('md-tooltip',{staticClass:"md-layout-item md-size-33 md-small-size-66"},[_vm._v(" Exibe a taxa de Maus em cada "),_c('strong',[_vm._v("Safra")]),_vm._v(" de acordo com a "),_c('strong',[_vm._v("Data de Referência")]),_vm._v(" mapeada. ")])],1)]),_c('div',{staticClass:"cardDataBox"},[(_vm.liberaSafraDataRef && !_vm.errorMsgResposta && _vm.valido)?_c('p',{staticClass:"chartTitle"},[_c('strong',[_vm._v("Taxa de Evento por Safra")])]):_vm._e(),_c('div',{staticClass:"chart"},[(
          _vm.liberaSafraDataRef &&
          !_vm.varResposta &&
          !_vm.errorMsgResposta &&
          !_vm.liberaLoad
        )?_c('div',{staticClass:"chartBox",attrs:{"id":"mixedChartDefault"}},[_c('MixedVazio',{attrs:{"id":2}})],1):_vm._e(),(
          _vm.liberaSafraDataRef &&
          _vm.varResposta &&
          !_vm.errorMsgResposta &&
          _vm.liberaChart &&
          _vm.valido
        )?_c('div',{staticClass:"chartBox"},[_c('MixedSafraConceito',{key:_vm.respMau,attrs:{"id":4,"eventoPorSafra":_vm.eventoPorSafra,"respMau":_vm.respMau}})],1):_vm._e(),(_vm.varResposta && !_vm.errorMsgResposta && !_vm.valido)?_c('md-card',{staticClass:"cardError"},[_c('span',{staticClass:"material-symbols-outlined icon"},[_vm._v("fmd_bad")]),_c('h5',{staticClass:"task errorAlert"},[_vm._v(" Selecione uma "),_c('strong',[_vm._v("Variável Resposta")]),_vm._v(" válida ")])]):_vm._e(),(_vm.errorMsgResposta && !_vm.varResposta)?_c('md-card',{staticClass:"cardError"},[_c('span',{staticClass:"material-symbols-outlined icon-error task"},[_vm._v("highlight_off")]),_c('h5',{staticClass:"task errorAlert"},[_vm._v(_vm._s(_vm.errorMsgResposta))])]):_vm._e()],1)]),(!_vm.liberaSafraDataRef)?_c('div',{staticClass:"boxDisable"},[_c('md-card',{staticClass:"cardDisable"},[_c('span',{staticClass:"material-symbols-outlined icon"},[_vm._v("fmd_bad")]),_c('p',{staticClass:"textData errorAlert"},[_vm._v(" As informações relacionadas à "),_c('strong',[_vm._v("Safra")]),_vm._v(" estão "),_c('strong',[_vm._v("desabilitadas")]),_vm._v(" pois não foi mapeada uma "),_c('strong',[_vm._v("Data de Referência")]),_vm._v(" para este Lote. ")])])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }